import * as papa from 'papaparse';
import * as BPromise from 'bluebird';
import dateParser from './dateParser';
import { AppUser } from 'Src/views/App/App';
import { createForm, fetchAllForms } from 'Src/helpers/service/admin/forms';
import { createHouse, fetchAllHouses } from 'Src/helpers/service/admin/houses';
import { ImportError } from 'Src/helpers/utils/types';

interface CSVParseResult {
  data: any[];
  errors: any[];
  meta: { fields: string[] };
}

export const parseCSVFile = (file: File, config: object): Promise<CSVParseResult> => {
  return new BPromise((resolve, reject) => {
    const effectiveConfig = {
      ...config,
      complete: (results: CSVParseResult) => resolve(results),
      error: (error: Error) => reject(error)
    };

    papa.parse(file, effectiveConfig);
  });
};

export const guessTable = {
  firstName: ['firstName', 'first_name', 'firstname', 'name', 'forename', 'First Name', 'Name', 'Forename'],
  lastName: ['lastName', 'last_name', 'lastname', 'surname', 'Last Name', 'Surname'],
  gender: ['gender', 'sex', 'Gender', 'Sex'],
  birthday: ['birthday', 'date_of_birth', 'dob', 'Birthday', 'Date of Birth', 'DOB'],
  year: ['year', 'grade', 'classYear', 'Year', 'Grade', 'Class Year'],
  form: ['form', 'classForm', 'Form', 'Class Form', 'Class'],
  house: ['house', 'team', 'houseName', 'House', 'House Name']
};

export const guessColumn = (fieldNames: string[], fieldToGuess: string): string | undefined => {
  const possibleValues = guessTable[fieldToGuess];
  if (!Array.isArray(possibleValues)) return undefined;
  return fieldNames.find(fieldName =>
    possibleValues.some(value => value.toLowerCase() === fieldName.trim().toLowerCase())
  );
};

export const guessHeaders = (fieldNamesArray: string[]) => {
  const headers = {
    firstName: guessColumn(fieldNamesArray, 'firstName'),
    lastName: guessColumn(fieldNamesArray, 'lastName'),
    gender: guessColumn(fieldNamesArray, 'gender'),
    birthday: guessColumn(fieldNamesArray, 'birthday'),
    year: guessColumn(fieldNamesArray, 'year'),
    form: guessColumn(fieldNamesArray, 'form'),
    house: guessColumn(fieldNamesArray, 'house')
  };
  return headers;
};

export const objectToStudent = (headers: any, obj: any) => {
  const student = {
    firstName: obj[headers.firstName]?.trim(),
    lastName: obj[headers.lastName]?.trim(),
    gender: obj[headers.gender] ? guessGender(obj[headers.gender]) : undefined,
    birthday: obj[headers.birthday] ? dateParser(obj[headers.birthday]) : undefined,
    formName: obj[headers.form]?.trim(),
    houseName: obj[headers.house]?.trim(),
    year: obj[headers.year] ? obj[headers.year].trim() : undefined
  };
  return student;
};

export const guessGender = (genderValue: string): string => {
  const lowGender = genderValue.toLowerCase();
  if (['boy', 'male', 'Male', '1'].includes(lowGender)) return 'MALE';
  if (['girl', 'female', 'Female', '0'].includes(lowGender)) return 'FEMALE';
  return genderValue;
};

export const readStudentsFromCSVFile = async (
  file: File
): Promise<{ students: any[]; errors: ImportError[]; meta: any }> => {
  const result = await parseCSVFile(file, { header: true, skipEmptyLines: true });
  const data = result.data || [];
  const origHeaders = result.meta.fields || [];
  const guessedHeaders = guessHeaders(origHeaders);

  const { isValid, missingHeaders } = validateHeader(guessedHeaders);
  const errors: ImportError[] = [];

  if (!isValid) {
    errors.push({
      type: 'MissingHeaders',
      code: 'MissingHeaders',
      message: `The following mandatory headers are missing: ${missingHeaders.join(', ')}`,
      row: 0
    });
    return {
      students: [],
      errors,
      meta: result.meta
    };
  }

  const mandatoryFields = ['firstName', 'lastName', 'gender', 'year'];
  const studentArray = data
    .filter(item => Object.keys(item).length > 1)
    .map((item, index) => {
      const student = objectToStudent(guessedHeaders, item);
      const missingFields = mandatoryFields.filter(field => !student[field]);

      if (missingFields.length > 0) {
        errors.push({
          type: 'MissingData',
          code: 'MissingData',
          message: `Row ${index + 1} is missing mandatory fields: ${missingFields.join(', ')}`
        });
      }

      return student;
    });

  if (errors.length > 0) {
    return {
      students: [],
      errors,
      meta: result.meta
    };
  }

  return {
    students: studentArray.length > 0 ? studentArray : [],
    errors,
    meta: result.meta
  };
};

export const pullFormsHouses = async (result: any, school: any, user: AppUser) => {
  const activeForms = await fetchAllForms(user);
  const activeHouses = await fetchAllHouses(user);

  const filteredForms = activeForms.filter((form: any) => form.status === 'ACTIVE');
  const filteredHouses = activeHouses.filter((house: any) => house.status === 'ACTIVE');

  const createdFormsMap = new Map<string, any>();
  const createdHousesMap = new Map<string, any>();

  const formCreationLock = {};
  const houseCreationLock = {};

  if (!Array.isArray(result.students)) {
    console.error('Result.students is not an array:', result.students);
    return result;
  }

  const findOrCreateForm = async (formName: string, age: number) => {
    const formIdentifier = `${formName.trim().toLowerCase()}-${age}`;

    if (formCreationLock[formIdentifier]) {
      await formCreationLock[formIdentifier];
    }

    let form = createdFormsMap.get(formIdentifier);
    if (!form) {
      formCreationLock[formIdentifier] = createForm(user, { name: formName, age });

      try {
        form = await formCreationLock[formIdentifier];
        if (form) {
          filteredForms.push(form);
          createdFormsMap.set(formIdentifier, form);
        }
      } catch (error) {
        console.error(`Error creating form: ${error.message}`);
        return { error: true };
      } finally {
        delete formCreationLock[formIdentifier];
      }
    }

    return form;
  };

  const findOrCreateHouse = async (houseName: string) => {
    const houseIdentifier = houseName.trim().toLowerCase();

    if (houseCreationLock[houseIdentifier]) {
      await houseCreationLock[houseIdentifier];
    }

    let house = createdHousesMap.get(houseIdentifier);
    if (!house) {
      houseCreationLock[houseIdentifier] = createHouse(user, { name: houseName });

      try {
        house = await houseCreationLock[houseIdentifier];
        if (house) {
          filteredHouses.push(house);
          createdHousesMap.set(houseIdentifier, house);
        }
      } catch (error) {
        console.error(`Error creating house: ${error.message}`);
        return { error: true };
      } finally {
        delete houseCreationLock[houseIdentifier];
      }
    }

    return house;
  };

  result.students = await Promise.all(
    result.students.map(async (student: any, i: number) => {
      let formNameToUse = student.formName;
      if (!formNameToUse && student.year) {
        formNameToUse = `Year ${student.year}`;
      }

      const studentAge = parseInt(student.year, 10);
      if (isNaN(studentAge)) {
        console.error(`Invalid age parsed from year: ${student.year}`);
      }

      const form = await findOrCreateForm(formNameToUse, studentAge);
      if (form && !form.error) {
        student.formId = form.id;
      } else {
        result.errors.push({
          type: 'StudentFormCreationFailed',
          code: 'StudentFormCreationFailed',
          message: `Failed to create form: ${formNameToUse}. Student: ${student.firstName} ${student.lastName}`,
          row: i
        });
      }

      const houseName = student.houseName;
      if (houseName) {
        const house = await findOrCreateHouse(houseName);
        if (house && !house.error) {
          student.houseId = house.id;
        } else {
          result.errors.push({
            type: 'StudentHouseCreationFailed',
            code: 'StudentHouseCreationFailed',
            message: `Failed to create house: ${houseName}. Student: ${student.firstName} ${student.lastName}`,
            row: i
          });
        }
      }

      return student;
    })
  );

  return result;
};

export const validateHeader = (headers: any) => {
  const mandatoryHeaders = ['firstName', 'lastName', 'gender', 'year'];
  const missingHeaders: string[] = [];

  mandatoryHeaders.forEach(header => {
    if (!headers[header]) {
      console.error(`Missing mandatory header: ${header}`);
      missingHeaders.push(header);
    }
  });

  return { isValid: missingHeaders.length === 0, missingHeaders };
};
